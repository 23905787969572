<template>
  <div class="manage-auctions" v-if="auctions && isOlta">
    <h3>Manage Auctions</h3>
    <button class="refresh-button" @click="refresh">Refresh</button>
    <table class="auctions">
      <tr>
        <th>Auction Id</th>
        <th>Token Id</th>
        <th>Status</th>
        <th>Approved</th>
        <th>Approve</th>
        <th>Reject</th>
        <th>Cancel</th>
        <th>Reserve Price</th>
      </tr>
      <tr
        class="auction"
        :class="auction.status.toLowerCase()"
        v-for="auction in auctions"
        :key="auction.id"
        >
        <td>{{ auction.id }}</td>
        <td>{{ auction.media.id }}</td>
        <td>{{ auction.status }}</td>
        <td>{{ auction.approved }}</td>
        <td>
          <button v-if="isWaitingApproval(auction)" @click="() => approveAuction(auction.id)">Approve</button>
        </td>
        <td>
          <button v-if="isWaitingApproval(auction)" @click="() => rejectAuction(auction.id)">Reject</button>
        </td>
        <td>
          <button v-if="canCancel(auction)" @click="() => cancelAuction(auction.id)">Cancel</button>
        </td>
        <td>
          <div v-if="canCancel(auction)">
            <input
              class="reserve-price-input"
              type="number"
              name="newReservePrice"
              v-model="auction.newReservePrice"
              > ETH
            <button @click="() => updateAuctionReserve(auction.id, auction.newReservePrice)">Update</button>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { getSigner, zoraGraphRinkeby, zoraGraph} from '../services.js'
import { AuctionHouse } from '@zoralabs/zdk'
import { gql } from 'graphql-request'
import { utils } from 'ethers'

// TODO: make work on mainnet/rinkeby
// TODO: specify olta address
// TODO: account for other currencies

export default defineComponent({
  setup() {

    const auctions = ref(null)

    const AuctionHouseFromSigner = async () => {
      const signer = await getSigner()
      const chainId = await signer.getChainId()
      if(chainId !== 4) {
        console.log("WRONG NETWROK CHANGE TO RINKYBY")
      }
      return new AuctionHouse(signer, chainId)
    }

    const cancelAuction = async (auctionId) => {
      const auctionHouse = await AuctionHouseFromSigner()
      const cancelAuctionTx = await auctionHouse.cancelAuction(auctionId)
      console.log('canceling auction')
      cancelAuctionTx.wait()
      console.log('confirmed')
    }

    const approveAuction =  async (auctionId) => {
      const auctionHouse = await AuctionHouseFromSigner()
      const resp = await auctionHouse.setAuctionApproval(auctionId, true)
      console.log("Waiting for confimation")
      await resp.wait()
      console.log("Approved")
    }

    const rejectAuction = async (auctionId) => {
      const auctionHouse = await AuctionHouseFromSigner()
      const resp = await auctionHouse.setAuctionApproval(auctionId, false)
      console.log("Waiting for confimation")
      await resp.wait()
      console.log("Rejected")
    }

    const updateAuctionReserve = async (auctionId, newReserve) => {
      const auctionHouse = await AuctionHouseFromSigner()
      const parsedNewReserve = utils.parseUnits(String(newReserve), "ether")
      try{
        const resp = await auctionHouse.setAuctionReservePrice(auctionId, parsedNewReserve)
        console.log("Waiting for confimation")
        await resp.wait()
        console.log("reserve updated")
      } catch (error){
        console.log("error", error)
      }
    }

    const loadAuctions = async () => {
      const signer = await getSigner()
      const address = (await signer.getAddress()).toLowerCase()

      const query =  gql`{
        reserveAuctions(where: {
            curator: "${address}",
        }){
          id
          status
          approved
          duration
          expectedEndTimestamp
          tokenOwner {
            id
          }
          reservePrice
          auctionCurrency{
            name
            symbol
            decimals
            id
          }
          currentBid{
            bidder {
              id
            }
            amount
            createdAtTimestamp
            transactionHash
          }
          media {
            id
          }
        }
      }`
      const chainId = await signer.getChainId()
      if(chainId === 4){
        const resp = await zoraGraphRinkeby.request(query)
        return resp.reserveAuctions.reverse()
      }

      const resp = await zoraGraph.request(query)
      return resp.reserveAuctions.reverse()
    }

    const refresh = async () => {
      auctions.value = await loadAuctions()
      auctions.value = formatValues(auctions.value)
    }
    const isOlta = ref(false)
    const checkIsOlta = async () => {
      const signer = await getSigner()
      const address = (await signer.getAddress())
      isOlta.value = (address === '0x2c5E0aA28870006e2daFB2d2B8900135F8b4fCEf')
    }

    const canCancel = ({status, currentBid}) => (status === 'Active' && !currentBid)
    const isWaitingApproval = ({status, approved}) => (status === 'Pending' && !approved)

    const formatValues = auctionMany => {
      if(!auctionMany && auctionMany.length === 0) return

      return auctionMany.map( auction => {
        const newReservePrice =  utils.formatUnits(String(auction.reservePrice), "ether")
        return {
          ...auction,
          newReservePrice
        }
      })
    }

    onMounted(async () => {
      await checkIsOlta()
      auctions.value = await loadAuctions()
      auctions.value = formatValues(auctions.value)
    })

    return {
      auctions,
      refresh,
      canCancel,
      isWaitingApproval,
      cancelAuction,
      rejectAuction,
      updateAuctionReserve,
      approveAuction,
      isOlta
    }
  },
})
</script>

<style lang="scss" scoped>
  .manage-auctions {
    margin: 2em;
    max-width: 800px;
  }
  .reserve-price-input{
    width: 50%;
  }
  .auctions {
    width: 100%;
    max-width: 880px;
    text-align: left;
    border-spacing: 0px;
    td, th{
      border-bottom: 1px solid black;
      padding: 1em 0.5em 0em 0.5em;
    }

    tr {
      &.finished{
        opacity: 0.6;
      }
    }
  }
</style>
